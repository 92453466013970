<script setup>
import { useSettings } from "~/composables/useSettings";

const { settings } = useSettings();

const props = defineProps(["singleBible"]);

const bookName = computed(() => {
  return props.singleBible?.location?.bookModel?.bookNameCanonLong;
});
</script>
<template>
  <div class="settings-panel">
    <span class="left">{{ bookName }}</span>
    <span><img src="/icons/clock.svg" alt="" /></span>
    <span><img src="/icons/two-columns.svg" alt="" /></span>
    <span><img src="/icons/settings.svg" alt="" /></span>
  </div>
</template>
<style scoped>
.settings-panel {
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  background-color: var(--root-white);
  color: #9fa2b6;
  display: grid;
  grid-template-columns: 1fr 40px 40px 40px;

  margin-top: 40px;
  max-width: var(--width-mobile);
}
.settings-panel span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
span.left {
  justify-content: flex-start;
}
</style>
